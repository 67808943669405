import '../admin/js/helpers.js';
import '../admin/js/template-customizer.js';
import '../admin/js/config.js';
import { createPopper } from '@popperjs/core';
import 'bootstrap';
import $ from 'jquery';
import 'node-waves/dist/waves.js';
import 'perfect-scrollbar/dist/perfect-scrollbar.js';
import Hammer from 'hammerjs';
import '../admin/js/notification.js';
import '../admin/js/flatpickr.js';
import '../admin/js/menu.js';
import '../admin/js/main.js';

window.$ = $;
